import * as React from "react";
import { GlobalStyles } from '@soluto-private/mx-asurion-ui-react';
import HeroImage from "./images/hero-mjp-F@2x.jpg";
import Hero from "./components/Hero/index.js"


const App = () => {
  return (
      <>
      <GlobalStyles />
      <Hero
        img={HeroImage}
        headline="Your tech. Fixed."
        subHeadline="From breaks to bugs, we have all your devices covered."
      />
      </>
  )
};

export default App
