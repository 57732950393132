import styled, { keyframes, css } from "styled-components/macro"
import { getFontSize, getFontWeight } from '@soluto-private/mx-asurion-ui-react';
import { device } from '../../../abstracts/breakpoints';
import CloseIcon from '../../../images/icon-close.svg';
import BackIcon from '../../../images/icon-chevron-left.svg';

const slideDown = keyframes`
 0% { max-height: 0; opacity:1 }
 10% {opacity:1 box-shadow: 0px 0px 0px rgb(0 0 0 / 10%);}
 100% { max-height: 600px; box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);}

`

export const Container = styled.div`
  position:absolute;
  width:100%;
  background:white;
  top:0;
  border-radius:2rem;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
  padding:1.5rem;
  padding-top:4.5rem;
  overflow:hidden;
  @media ${device.tablet} {
    padding-bottom:100px;
  }
  animation: ${props =>
    (props.shouldAnimate ?
      css`${slideDown} ${props.animationDuration}ms cubic-bezier(.09,.23,.35,1.01)` :
      css``
    )} ;

  }}


  @media ${device.tablet} {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 5;
    padding-top: 0px;
    overflow: scroll;
    max-width: calc(100vh - 2rem);
    border-radius: 0;
    height: 100vh;
    opacity:1;
    animation:none;
}
  }
`;
export const Nav = styled.nav`
  display:flex;
  align-items:flex-start;
  margin-top:1rem;
  @media ${device.tablet} {
    flex-wrap: wrap;
  }
`;

export const Header = styled.div`
  display:flex;
  align-items: center;
  min-height:3rem;
  @media ${device.tablet} {
    order: 3;
      margin-top:1.5rem;
  }
  ${props => {
        if (props.hasHeader === 'onMobile') return `
          clip: rect(0 0 0 0);
          clip-path: inset(50%);
          height: 1px;
          overflow: hidden;
          position: absolute;
          white-space: nowrap;
          width: 1px;
          @media ${device.tablet} {
                clip:initial;
                height:initial;
                overflow:initial;
                height:initial;
                position:relative;
                white-space:initial;
                width:initial;
                clip:unset;
                clip-path:unset;
                width:100%;

          }
        `
    }}
`;


export const BackButton = styled.button`
  background:url(${BackIcon});
  background-repeat: no-repeat;
  background-position:center;
  background-size:auto 1rem;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  cursor:pointer;
  margin-right:1rem;
  margin-left: -1rem;
  @media ${device.tablet} {
    order: 1;
  }
`;
export const CloseButton = styled.button`

  display:none;
  @media ${device.tablet} {
    order: 2;
    background:url(${CloseIcon});
    background-size: 1rem;
    background-position: center;
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    background-repeat: no-repeat;
    cursor: pointer;
    position: absolute;
    right: 1.5rem;
    display: block;
    margin-right:-1rem;
  }
`;
export const Title = styled.h3`
  font-size: ${getFontSize(4)};
  font-weight: ${getFontWeight('feather')};
  line-height:135%;
  margin:0;


`;
export const Content = styled.div`
  display:grid;
`;
