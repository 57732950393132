import React from "react";
import styled from "styled-components";
import Popup from "../Popup";
import checkMark from "../../../images/checkmark-circle.svg";
import { device } from '../../../abstracts/breakpoints';

const Checkmark = styled.object`
  width: 100px;
  margin: auto;
  @media ${device.mobileL} {
    align-self:flex-start;
    margin-top:20vh
  }
`;
const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: #ffffffbf;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContentFiller = styled.div`
  min-height:150px;
`;

const InterstitialStep = (props) => {

  return(
    <Popup
      data={props.data}
      handleBack={props.handleBack}
      handleClose={props.handleClose}
      shouldAnimate={props.shouldAnimate}
      handleFirstAnimation={props.handleFirstAnimation}
      hasBackButton="false"
      hasHeader="false"
    >
    <ContentFiller/>
    <Overlay>
      <Checkmark type="image/svg+xml" data={checkMark} alt="Check!"></Checkmark>
    </Overlay>
    </Popup>
  );
};

export default InterstitialStep;
