import React, { useState, useEffect, useRef} from "react";
import * as S from "./styles";
import ProductStep from "./Steps/ProductStep";
import PerilStep from "./Steps/PerilStep";
import CoverageStep from "./Steps/CoverageStep";
import InterstitialStep from "./Steps/InterstitialStep";
import { Button } from '@soluto-private/mx-asurion-ui-react';
import { ProductTypes, ImagesForPreload } from "../../data/ProductTypes.js";
import Tag from "../Tag";
import ShieldIcon from "../../images/icon-shield-animated.svg";
import ChevronIcon from "../../images/icon-chevron-down.svg";
import Geocode from "react-geocode";
import { size } from '../../abstracts/breakpoints';
import useWindowDimensions from '../../abstracts/useWindowDimensions';

const FixItBar = () => {
  const data = ProductTypes;

  const [isOpen, setIsOpen] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(true);

  const [step, setStep] = useState({
    current:''
  });
  const [route, setRoute] = useState('http://www.asurion.com/claims');
  const [userInput, setUserInput] = useState({
      product:'',
      peril:'',
      coverage:''
    }
  );
  const [zipCode, setZipCode] = useState()

// set initial zipcode with geolocation
  useEffect(() => {

    navigator.geolocation.getCurrentPosition(function(position) {
      Geocode.setApiKey("AIzaSyBPawmI-3dNJJknpLqHQAGd-RuXA5bOyj8");
      Geocode.setLocationType("ROOFTOP");
      Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
        (response) => {
          const address = response.results[0].address_components[7].long_name;
          setZipCode(address)
        },
        (error) => {
          console.error(error);
        }
      );
    });

    ImagesForPreload.forEach((image) => {
        const newImage = new Image();
        newImage.src = image;
        window[image] = newImage;
    });
    if (userInput.coverage) {
      zipCodeInputRef.current.focus();
    }
  }, [userInput, route])

  const handleFirstAnimation = () =>{
    setShouldAnimate(false)
  }

  const handleBack = () => {
    if (step.current === 'PerilStep'){
      setStep({current: 'ProductStep'});
      setUserInput({
        ...userInput,
        product:''
      });
    }
    else if (step.current === 'CoverageStep' && userInput.peril){
      setStep({current: 'PerilStep'});
      setUserInput({
        ...userInput,
        peril:''
      });
    }
    else if (step.current === 'CoverageStep' && !userInput.peril){
      setStep({current: 'ProductStep'});
      setUserInput({
        ...userInput,
        product:''
      });
    }
    else{
      setStep({current: ''});
      setUserInput({
        product:'',
        peril:'',
        coverage:''
      });
    }

  }

  const handleProductSelection = (stepData) => {
    setUserInput({
      ...userInput,
      product: stepData
    });
    if ( stepData.route ){
      setRoute( stepData.route );
    }
    setStep({current: stepData.nextStep});

  };
  const handlePerilSelection = (stepData) => {
    setUserInput({
      ...userInput,
      peril:stepData
    });
    if (stepData.route ){
      setRoute( stepData.route );
    };
    setStep({current: stepData.nextStep});

  };

    const handleCoverageSelection = (stepData) => {
    setUserInput({
      ...userInput,
      coverage:stepData
    });
    if (stepData.route ){
      setRoute(stepData.route);
    };
    setStep({current: stepData.nextStep});

    setTimeout(function(){
      handleSubmit(stepData.route);
    }, 1500);
  };
  const goToProductStep = () => {
      setUserInput({
        product:'',
        peril:'',
        coverage:''
      });
      setStep({current:'ProductStep'});
      setRoute( null );
  };
  const goToPerilStep = () => {
      setUserInput({
        ...userInput,
        peril:''
      });
      setStep({current:'PerilStep'})
      if (userInput.product.route ){
        setRoute( userInput.product.route )
      }
      else{
        setRoute(null)
      };
  };


  const handleOpen = () => {
    if (!step.current){
      setStep({current:'ProductStep'})
    }
    setIsOpen( true )

  }

  const { width } = useWindowDimensions();

  const handleClose = () => {
      setIsOpen( false )
      setShouldAnimate( true )
      if ( width <= size.tablet.replace('px','') ){
        setStep({current:''})
        setUserInput({
          product:'',
          peril:'',
          coverage:''
        });
      }
      console.log("window width = " + width)
      console.log(size.tablet.replace('px',''))
      console.log(step.current)
  }

  const handleSelectClick = () => {
    if( isOpen ){
      if( !userInput.product ){
        handleClose()
      }
    }
    else{
      handleOpen()
    }
  }
  const handleSubmit = (r) =>{

    if( r && r.startsWith('https://new.ubreakifix.com/?device=') && zipCode ){
      window.location.href = r + '&postal_code=' + zipCode;
    }
    else if( r ){
      window.location.href = r;
    }
    else{
      console.log('Sorry, no route set.')
    }
  };

  const handleCoverageIcon = () => {
    console.log( userInput.coverage )
    if ( userInput.coverage.value === 'yes_protection'){
      return ShieldIcon
    }
  }

  const zipCodeInputRef = useRef(null);

  const handleInputChange = (event) => {
    setZipCode(event.target.value);
  }


  return(
    <>
    {step.current && (
      <S.Background
        onClick={ () => handleClose() }
        data-analytics-id="Homepage | FixitBar | clicked_close"
      />
    )}
    <S.Container>
      <S.Controls>
        <S.Fields>
          <S.SelectDeviceDiv
            onClick={ () => handleSelectClick() }
            data-analytics-id="Homepage | FixitBar | clicked_open"
          >
            {!userInput.product &&(
              <S.Placeholder>What can we fix for you?</S.Placeholder>
            )}
            {!userInput.product && (
              <S.Chevron isOpen={isOpen}>
                <img src={ChevronIcon} alt="dropdown icon"/>
              </S.Chevron>
            )}

            {userInput.product && (
              <>
              <S.Qualifier>Fix my </S.Qualifier>
              <Tag
                icon={ handleCoverageIcon() }
                title={userInput.product.title.toLowerCase()}
                onClick={() => goToProductStep()}
              />
              </>
            )}
            {userInput.peril && (
              <>
              <S.Qualifier>{userInput.peril.qualifier}</S.Qualifier>
              <Tag
                title={userInput.peril.title.toLowerCase() +"."}
                onClick={() => goToPerilStep()}
              />
              </>
            )}
          </S.SelectDeviceDiv>
          <S.ZipcodeField>
            <input
              type="text"
              id="zipcodeInput"
              data-analytics-id="Homepage | FixitBar | focused_zipcode"
              ref={zipCodeInputRef}
              value={zipCode}
              required
              onChange={handleInputChange}
            />
            <label htmlFor="zipcodeInput">ZIP code</label>
          </S.ZipcodeField>
        </S.Fields>
        <S.SubmitButton>
          <Button
            onClick={() => handleSubmit(route)}
            size="medium"
            data-analytics-id="Homepage | FixitBar | clicked_submit"
            style={{width: "100%"}}>
            Fix it
          </Button>
        </S.SubmitButton>
      </S.Controls>
      {step.current === 'ProductStep' && isOpen &&(
        <ProductStep
          data={data}
          handleSelection={handleProductSelection}
          handleClose={handleClose}
          handleBack={handleBack}
          shouldAnimate={shouldAnimate}
          handleFirstAnimation={handleFirstAnimation}
          isOpen={isOpen}
          userInput={userInput}
        />
      )}
      {step.current === 'PerilStep' && isOpen && (
        <PerilStep
          data={userInput.product}
          handleSelection={handlePerilSelection}
          handleClose={handleClose}
          handleBack={handleBack}
          shouldAnimate={shouldAnimate}
          handleFirstAnimation={handleFirstAnimation}
          isOpen={isOpen}
          userInput={userInput}
        />
      )}
      {step.current === 'CoverageStep' && isOpen &&(
        <CoverageStep
          data={userInput.product}
          handleSelection={handleCoverageSelection}
          handleClose={handleClose}
          handleBack={handleBack}
          shouldAnimate={shouldAnimate}
          handleFirstAnimation={handleFirstAnimation}
          isOpen={isOpen}
          userInput={userInput}
        />
      )}
      {step.current === 'InterstitialStep' && isOpen &&(
        <InterstitialStep
          data={userInput.product}
          handleSelection={handleCoverageSelection}
          handleClose={handleClose}
          handleBack={handleBack}
          shouldAnimate={shouldAnimate}
          handleFirstAnimation={handleFirstAnimation}
          isOpen={isOpen}
        />
      )}
    </S.Container>
    </>
  )
};

export default FixItBar;
