import React from "react";
import * as S from "./styles";
import FixItBar from "../FixItBar";

const Hero = ( props ) => {

  return(
    <S.Hero>
      <S.Container img={props.img}>
        <S.Content>
          <S.HeadlineGroup>
            <S.Headline>
              {props.headline}
            </S.Headline>
            <S.SubHeadline>
              {props.subHeadline}
            </S.SubHeadline>
          </S.HeadlineGroup>
          <S.FixItBarContainer>
            <FixItBar />
          </S.FixItBarContainer>
        </S.Content>
      </S.Container>
    </S.Hero>
  )
};

export default Hero;
