import styled from "styled-components/macro"
import { getFontSize, getFontWeight } from '@soluto-private/mx-asurion-ui-react';
import { device } from '../../../abstracts/breakpoints';


export const MatrixContainer = styled.div`
  display:flex;
  gap:1px;
  background:#E8E8E8;
  margin-top:1rem;
  flex-wrap:wrap;
  transition: 5s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
`;


export const MatrixItem = styled.div`
  background:white;
  padding:0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => {
    if (props.cols === "3") {
      return `width: calc(33.333% - 0.8px);`
  } else if (props.cols === "2") {
      return `width: calc(50% - 0.8px);`
    } else {
      return `width: calc(25% - 0.8px);`
    }
  }}
  @media ${device.mobileL} {
    ${props => {
      if (props.cols === "3") {
        return `width: calc(50% - 0.5px);`
    } else if (props.cols === "2") {
        return `width: calc(50% - 0.5px);`
      } else {
        return `width: calc(50% - 0.5px);`
      }
    }}
  }
  &:last-child{
    flex-grow:1;
    justify-content: flex-start;
    @media ${device.mobileL} {
      justify-content: center;
    }
  }
`;

export const MatrixButton = styled.button`
  background:white;
  border:none;
  cursor:pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 130px;
  align-items: center;
  width:250px;
  padding-top:1rem;
  img, svg{
    max-width:4rem;
  }
`;

export const MatrixItemTitle = styled.h4`
color:black;
font-size: ${getFontSize(2)};
font-weight: ${getFontWeight('feather')};
`;
