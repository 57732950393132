import React, { useState, useEffect } from "react";
import * as S from "./styles";
import { Transition } from 'react-transition-group';

const duration = 350;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  transitionDelay: '50ms',
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:  { opacity: 0 },
};

const Matrix = (props) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true)
  },[])

const DataAnalyticsIdString = (userInput, entry) =>{
  const page = "Homepage";
  const component = "FixitBar";
  const product = userInput.product.value;
  const peril = userInput.peril.value;
  if( userInput.product ){
    return page + " | " + component + " | " + product + " | clicked_" + entry.value
  }
  if( userInput.peril ){
    return page + " | " + component + " | " + product + " | " + peril + " | clicked_" + entry.value
  }
  else{
    return page + " | " + component + " | " + entry.value
  }
}

  return(
    <Transition in={animate} timeout={duration}>
      {(state) =>
        <S.MatrixContainer style={{
        ...defaultStyle,
        ...transitionStyles[state]
      }}>
          {props.data.map((entry) =>
            <S.MatrixItem
              key={entry.value}
              cols={props.cols}
              tabindex="2"
            >
              <S.MatrixButton
                onClick={() => props.handleSelection(entry)}
                data-analytics-id={DataAnalyticsIdString(props.userInput, entry)}
              >
                  <img src={entry.img} alt="{entry.title} + icon"/>
                <S.MatrixItemTitle>{entry.title}</S.MatrixItemTitle>
              </S.MatrixButton>
            </S.MatrixItem>
         )}
        </S.MatrixContainer>
      }
    </Transition>
  )

};

export default Matrix;
