import styled from "styled-components/macro";
import { getFontSize, getFontWeight } from '@soluto-private/mx-asurion-ui-react';
import { device } from '../../abstracts/breakpoints';

export const Hero = styled.div`
  background:linear-gradient(180deg,rgb(119 76 192) 63%,rgb(94 53 156) 83%);
  display: flex;
  justify-content: center;
`
export const Container = styled.div`
  *{
    box-sizing: border-box;
  }
  background-image:
    url(${props => props.img});
  background-position: right;
  background-size: auto 100%;
  background-repeat:no-repeat;
  height:520px;
  width:100%;
  max-width:1100px;
  display:flex;
  align-items:center;
  @media ${device.laptop} {
    background-position:460px;
  }
  @media ${device.tablet} {
    height: 480px;
    background-position:340px;
  }
  @media ${device.mobileL} {
    height:300px;
    max-width: 400px;
    background-position:150px;
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileS} {
  }
`;
export const Content = styled.div`
  margin:auto;
  width:945px;
  @media ${device.laptopL} {
    margin:auto
  }
  @media ${device.laptop} {
    margin:0 2.5rem;
  }
  @media ${device.tablet} {
    margin:0 2rem;
  }
  @media ${device.mobileL} {
    margin:0 1.5rem;
  }
`;

export const HeadlineGroup = styled.hgroup`
    width: 500px;
    @media ${device.tablet} {
      width:400px;
    }
    @media ${device.mobileL} {
      width:200px;
    }
`;
export const Headline = styled.h1`
  color:white;
  font-size: ${getFontSize(8)};
  font-weight: ${getFontWeight('feather')};
  margin:0;
  @media ${device.tablet}{
    font-size: ${getFontSize(7)};
  }
  @media ${device.mobileL}{
    font-size: ${getFontSize(6)};
  }
`;
export const SubHeadline = styled.h2`
  color:white;
  margin-top:1rem;
  font-size: ${getFontSize(3)};
  font-weight: ${getFontWeight('feather')};
  line-height:125%;
  @media ${device.tablet}{
    font-size: ${getFontSize(3)};
  }
  @media ${device.mobileL}{
    font-size: ${getFontSize(2)};
  }
`;
export const FixItBarContainer = styled.div`
  margin-top:4.5rem;
  @media ${device.mobileL}{
    margin-top: 2.5rem;
  }
`;
