import React, { useEffect } from "react";
import * as S from "./styles";

const animationDuration = 400;

const Popup = (props) => {
  useEffect(() => {
    setTimeout(function(){ props.handleFirstAnimation()}, animationDuration);
  }, [props])


  return(
        <S.Container
          role="dialog"
          aria-live="polite"
          aria-labelledby="dialogTitle"
          shouldAnimate={props.shouldAnimate}
          animationDuration={animationDuration}
        >
            <S.Nav>
              {props.hasBackButton !== "false" && (
                <S.BackButton onClick={props.handleBack}/>
                )
              }
                {props.hasHeader !== false && (
                <S.Header hasHeader={props.hasHeader}>
                  {props.title &&
                    <S.Title id="dialogTitle">{props.title}</S.Title>
                  }
                </S.Header>
              )}
              <S.CloseButton onClick={props.handleClose}/>
            </S.Nav>
            <S.Content>
              {props.children}
            </S.Content>
        </S.Container>
  )
}

export default Popup;
