import React from "react";
import styled from "styled-components/macro"
import { getFontSize } from '@soluto-private/mx-asurion-ui-react';
import { device } from '../../abstracts/breakpoints';
import CloseIcon from '../../images/icon-close.svg';

export const TagElement = styled.button`
  background:white;
  border:solid 1.5px #D5D6DA;
  border-radius:3rem;
  padding:0.35rem 1.45rem;
  color:#000000;
  font-size: ${getFontSize(2)};
  display:flex;
  align-items:center;
  margin-right:0.5rem;
  cursor:pointer;
  flex-shrink: 0;
  position:relative;
  transition:all 150ms;
  .closeButton{
    opacity:0;
  }
  &:hover{
    border:solid 1.5px #000;
    .closeButton{
      opacity:1;
    }
  }
  @media ${device.tablet} {
    font-size: ${getFontSize(1)};
  }
  @media ${device.mobileL}{
    margin-right:0;
  }
`;
export const RemoveButton = styled.i`
  position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    background: url(${CloseIcon}), #fff;
    background-position: center;
    background-repeat: no-repeat;
    right: 0.5rem;
    background-size: 100%;
`;
export const Icon = styled.object`
  width:16px;
  height:16px;
  margin-right: 0.5rem;
`;

const Tag = (props) => {
  return(
    <TagElement onClick={props.onClick} >
      { props.icon && (
        <Icon type="image/svg+xml" data={props.icon}/>
      )}
      {props.title}
      <RemoveButton className="closeButton" />
    </TagElement>
  )
}

export default Tag;
