import styled from "styled-components/macro";
import { getFontSize } from '@soluto-private/mx-asurion-ui-react';
import { device } from '../../abstracts/breakpoints';
import MapIcon from "../../images/location-pin-2.svg";


export const Background = styled.div`
  *{
    box-sizing: border-box;
  }
  width:100vw;
  height:100vh;
  left:0;
  top:0;
  position:fixed;
`;
export const Container = styled.div`
  position:relative;
  width:auto;
  display:inline-flex;
  @media ${device.laptopL} {
  }
  @media ${device.laptop} {
  }
  @media ${device.tablet} {
  }
  @media ${device.mobileL} {
    display:flex;
    flex-direction:column;

  }
`;

export const Chevron = styled.div`
  position: absolute;
  right: 1.5rem;
  @media ${device.mobileL} {
      transform:rotate(-90deg);
  }
  transition:transform 200ms;
  ${props =>
    (props.isOpen ?
      `transform: scaleY(-1);
      ` :
      ``
  )};

`;

export const SubmitButton = styled.div`
  position:relative;
  width:135px;
  @media ${device.mobileL} {
    width:100%;
    max-width: 300px;
    align-self: center;
    display:none;
  }
`;
export const Placeholder = styled.span`
  color:#000;
  margin-left:0;

`;

export const Controls = styled.div`
  display:inline-flex;
  background:white;
  border-radius:2rem;
  height:3.75rem;
  padding:.35rem;
  box-shadow: 0px 0px 20px 8px rgb(106 100 117 / 16%);
  min-width:600px;
  max-width:100%;
  z-index:1;
  position:relative;
  justify-content:space-between;


  @media ${device.laptopL} {
  }
  @media ${device.laptop} {

  }
  @media ${device.tablet} {
    display:flex;
    min-width:550px;
  }
  @media ${device.mobileL} {
    flex-direction:column;
    min-width:100%;
    height:auto;
    padding:0;
    box-shadow:none;
    background:none;

  }

`;
export const Fields = styled.div`
  align-items:center;
  display:flex;
  width: 100%;
  justify-content: space-around;}
  @media ${device.mobileL} {
    flex-direction:column;
  }
`;

export const SelectDeviceDiv = styled.div`
  height:100%;
  flex-grow:1;
  position:relative;
  display:flex;
  align-items:center;
  padding-left:1rem;
  position:relative;
  border-right:solid 1px #E8E8E8;
  cursor:pointer;
  border:none;
  background:transparent;
  font-size: ${getFontSize(2)};
  border-right:solid 1px #E8E8E8;
  margin-right:1rem;

  @media ${device.tablet} {
    font-size: ${getFontSize(1)};
  }
  @media ${device.mobileL} {
      margin-bottom:0.5rem;
      padding:1rem 2rem;
      width:100%;
      margin-right:0;
      min-height:3rem;
      height: auto;
      flex-wrap: wrap;
      box-shadow: 0px 0px 20px 8px rgb(0 0 0 / 18%) ;
      background:white;
      border-radius:2rem;
      margin-bottom:1rem;
      justify-content: flex-start;
      gap: 0.5rem;
  }
`;
export const Qualifier = styled.div`
  margin-right:0.5rem;
  color: #6e767d;
  flex-shrink: 0;
  @media ${device.mobileL} {
    margin-right:0;
  }
`;

export const ZipcodeField = styled.div`
  position:relative;
  height:100%;
  display:flex;
  align-items:center;
  width:90px;
  flex-shrink:1;
  flex-grow:0;
  margin-left:1.5rem;

  @media ${device.tablet} {
    width:70px;
  }
  @media ${device.mobileL} {
      margin-bottom:0.5rem;
      margin-right:0;
      margin-left:0;
      height:3rem;
      position: relative;
      left: 0.75rem;
      display:none;
}

  }
  input{
    margin-top:1rem;
    width:100%;
    border:none;
    font-size: ${getFontSize(2)};
    &:focus{
      outline:0;
    }
    @media ${device.tablet} {
      font-size: ${getFontSize(1)};
    }
    @media ${device.mobileL} {
      margin-top:0;
      font-size: ${getFontSize(2)};
    }

  }
  label{
    position:absolute;
    top:1rem;
    left:0;
    transition: all 0.2s ease-in-out,font-size 0.2s ease-in-out,padding 0.2s ease-in-out;
    display:flex;
    align-items:center;
    color:#6e767d;
    @media ${device.tablet} {
      font-size: ${getFontSize(1)};
    }

    &::before{
      content:'';
      background: url( ${MapIcon} );
      background-size:contain;
      background-repeat:no-repeat;
      display:block;
      width:1.25rem;
      height:1.25rem;
      position:absolute;
      left:-1.60rem;
      top:-.25rem;
    }
  }
  &:hover label{
    color:black;
    transition: color 200ms;
  }
  input:focus + label, input:valid + label{
    font-size: ${getFontSize(1)};
    top:.25rem;
    left:0;
    color:#000;
    @media ${device.mobileL} {
      top:0rem;
    }
  }


`;
