import React from "react";
import Popup from "../Popup";
import Matrix from "../Matrix";
import styled from "styled-components/macro"
import { getFontSize, getFontWeight, Link } from '@soluto-private/mx-asurion-ui-react';

const StepFooter = styled.div`

`;
const StepFooterContent = styled.p`
  font-size:${getFontSize(3)};
  font-weight:${getFontWeight('feather')};
  text-align:center;
  margin-bottom:1rem;
  margin-top:3rem;
`;
const StepFooterLink = styled.a`
  text-align:center;
  display:block;
`;
const ProductStep = (props) => {
  return(
    <Popup
      data={props.data}
      hasHeader="onMobile"
      hasBackButton="false"
      handleBack={props.handleBack}
      handleClose={props.handleClose}
      title={"What can we fix for you?"}
      shouldAnimate={props.shouldAnimate}
      handleFirstAnimation={props.handleFirstAnimation}

    >
      <Matrix
        data={props.data}
        cols= "4"
        handleSelection={props.handleSelection}
        userInput={props.userInput}
      />
      <StepFooter>
        <StepFooterContent>Need to start a claim with one of our retail partners?</StepFooterContent>
        <StepFooterLink><Link>Visit our claims center</Link></StepFooterLink>
      </StepFooter>
    </Popup>
  );
};

export default ProductStep;
