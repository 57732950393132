import React from "react";
import Popup from "../Popup";
import Matrix from "../Matrix";
import styled from "styled-components/macro";


const BoldElement = styled.span`
  font-weight:700;
`;

const styledTitle = (planType) => {
  return(
    <span>
    Do you have an <BoldElement>{planType}?</BoldElement>
    </span>
  )
};

const CoverageStep = (props) => {

  return(
    <Popup
      title={styledTitle(props.data.planType)}
      data={props.data}
      handleBack={props.handleBack}
      handleClose={props.handleClose}
      shouldAnimate={props.shouldAnimate}
      handleFirstAnimation={props.handleFirstAnimation}
    >
      <Matrix
        data={props.data.coverage}
        cols= "3"
        handleSelection={props.handleSelection}
        userInput={props.userInput}
      />
    </Popup>
  );
};

export default CoverageStep;
