import Appliance from "../images/appliance@2x.png";
import CellPhones from "../images/mobile-phone@2x.png";
import Game from "../images/gameconsole@2x.png";
import Instrument from "../images/somethingelse@2x.png";
import Laptop from "../images/laptop@2x.png";
import Tablet from "../images/tablet@2x.png";
import Thermostat from "../images/smarthome@2x.png";
import Watch from "../images/smartwatch@2x.png";
import CrackedScreen from "../images/icon-cracked.svg";
import Damage from "../images/icon-damaged.svg";
import StoppedWorking from "../images/icon-stopped-working.svg";
import LostStolen from "../images/icon-lost-stolen.svg";
import WaterDamage from "../images/icon-water-damage.svg";
import SomethingElse from "../images/icon-battery.svg";
import YesProtection from "../images/shield-warning.svg";
import NoProtection from "../images/X-no.svg";
import DontKnowProtection from "../images/question-circle.svg";
import CheckMark from "../images/checkmark-circle.svg";

export const ImagesForPreload = [
  Appliance,
  CellPhones,
  Game,
  Instrument,
  Laptop,
  Tablet,
  Thermostat,
  Watch,
  CrackedScreen,
  Damage,
  StoppedWorking,
  LostStolen,
  WaterDamage,
  SomethingElse,
  YesProtection,
  NoProtection,
  DontKnowProtection,
  CheckMark
]
export const ProductTypes = [
    {
    "title" : "Mobile phone",
    "planType" : "Asurion Mobile Protection Plan",
    "id" : 1,
    "img" : CellPhones,
    "value" : "mobile_phone",
    "nextStep" : "PerilStep",
    "route":"http://www.asurion.com/claims",
    "peril" : [
        {
          "title" : "Cracked screen",
          "img" : CrackedScreen,
          "value" : "cracked_screen",
          "qualifier" : "that has a",
          "nextStep" : "CoverageStep",
          "route":""
        },
        {
          "title" : "Damaged",
          "img" : Damage,
          "value" : "damage",
          "qualifier" : "that's been",
          "nextStep" : "CoverageStep",
          "route":""
        },
        {
          "title" : "Stopped working",
          "img" : StoppedWorking,
          "value" : "stopped_working",
          "qualifier" : "that's",
          "nextStep" : "CoverageStep",
          "route":""
        },
        {
          "title" : "Lost or stolen",
          "img" : LostStolen,
          "value" : "lost_stolen",
          "qualifier" : "that's been",
          "nextStep" : "CoverageStep",
          "route":""
        },
        {
          "title" : "Water damage",
          "img" : WaterDamage,
          "value" : "water_damage",
          "qualifier" : "that has",
          "nextStep" : "CoverageStep",
          "route":""
        },
        {
          "title" : "Something else",
          "img" : SomethingElse,
          "value" : "something_else",
          "qualifier" : "with",
          "nextStep" : "CoverageStep",
          "route":""
        },
      ],
      "coverage" : [
        {
          "title" : "Yes",
          "img" : YesProtection,
          "value" : "yes_protection",
          "nextStep" : "InterstitialStep",
          "route":"http://www.asurion.com/claims"
        },
        {
          "title" : "No",
          "img" : NoProtection,
          "value" : "No_protection",
          "nextStep" : "InterstitialStep",
          "route":"https://new.ubreakifix.com/?device=9"
        },
        {
          "title" : "I'm not sure.",
          "img" : DontKnowProtection,
          "value" : "Dont_know_protection",
          "nextStep" : "InterstitialStep",
          "route":"http://www.asurion.com/claims"
        }
      ]
    },
    {
    "title" : "Tablet",
    "planType" : "Asurion protection plan",
    "id" : 2,
    "img" : Tablet,
    "value" : "tablet",
    "nextStep" : "CoverageStep",
    "route":"http://www.asurion.com/claims",
    "peril" : [],
    "coverage" : [
      {
        "title" : "Yes",
        "img" : YesProtection,
        "value" : "yes_protection",
        "nextStep" : "InterstitialStep",
        "route":"http://www.asurion.com/claims"
      },
      {
        "title" : "No",
        "img" : NoProtection,
        "value" : "No_protection",
        "nextStep" : "InterstitialStep",
        "route":"https://new.ubreakifix.com/?device=10"
      },
      {
        "title" : "I'm not sure.",
        "img" : DontKnowProtection,
        "value" : "Dont_know_protection",
        "nextStep" : "InterstitialStep",
        "route":"http://www.asurion.com/claims"
      }
    ]
    },
    {
    "title" : "Laptop",
    "planType" : "Asurion protection plan",
    "id" : 3,
    "img" : Laptop,
    "value" : "laptop",
    "nextStep" : "CoverageStep",
    "route":"http://www.asurion.com/claims",
    "peril" : [],
    "coverage" : [
      {
        "title" : "Yes",
        "img" : YesProtection,
        "value" : "yes_protection",
        "nextStep" : "InterstitialStep",
        "route":"http://www.asurion.com/claims"
      },
      {
        "title" : "No",
        "img" : NoProtection,
        "value" : "No_protection",
        "nextStep" : "InterstitialStep",
        "route":"https://new.ubreakifix.com/?device=11"
      },
      {
        "title" : "I'm not sure.",
        "img" : DontKnowProtection,
        "value" : "Dont_know_protection",
        "nextStep" : "InterstitialStep",
        "route":"http://www.asurion.com/claims"
      }
    ]
    },
    {
    "title" : "Game console",
    "planType" : "Asurion protection plan",
    "id" : 4,
    "img" : Game,
    "value" : "game_console",
    "nextStep" : "CoverageStep",
    "route":"",
    "peril" : [],
    "coverage" : [
      {
        "title" : "Yes",
        "img" : YesProtection,
        "value" : "yes_protection",
        "nextStep" : "InterstitialStep",
        "route":"http://www.asurion.com/homeplus"
      },
      {
        "title" : "No",
        "img" : NoProtection,
        "value" : "No_protection",
        "nextStep" : "InterstitialStep",
        "route":"https://new.ubreakifix.com/?device=46"
      },
      {
        "title" : "I'm not sure.",
        "img" : DontKnowProtection,
        "value" : "Dont_know_protection",
        "nextStep" : "InterstitialStep",
        "route":"http://www.asurion.com/homeplus"
      }
    ]
    },
    {
    "title" : "Appliance",
    "planType" : "Asurion protection plan",
    "id" : 5,
    "img" : Appliance,
    "value" : "appliance",
    "nextStep" : "CoverageStep",
    "route":"http://www.asurion.com/appliance-repair",
    "peril" : [],
    "coverage" : [
      {
        "title" : "Yes",
        "img" : YesProtection,
        "value" : "yes_protection",
        "nextStep" : "InterstitialStep",
        "route":"http://www.asurion.com/applianceplus"
      },
      {
        "title" : "No",
        "img" : NoProtection,
        "value" : "No_protection",
        "nextStep" : "InterstitialStep",
        "route":"http://www.asurion.com/appliance-repair"
      },
      {
        "title" : "I'm not sure.",
        "img" : DontKnowProtection,
        "value" : "Dont_know_protection",
        "nextStep" : "InterstitialStep",
        "route":"http://www.asurion.com/claims"
      }
    ]
    },
    {
    "title" : "Wearable",
    "planType" : "Asurion protection plan",
    "id" : 6,
    "img" : Watch,
    "value" : "watch",
    "nextStep" : "CoverageStep",
    "route":"",
    "peril" : [],
    "coverage" : [
      {
        "title" : "Yes",
        "img" : YesProtection,
        "value" : "yes_protection",
        "nextStep" : "InterstitialStep",
        "route":"https://asurion.com/claims"
      },
      {
        "title" : "No",
        "img" : NoProtection,
        "value" : "No_protection",
        "nextStep" : "InterstitialStep",
        "route":"https://new.ubreakifix.com/?device=1251"
      },
      {
        "title" : "I'm not sure.",
        "img" : DontKnowProtection,
        "value" : "Dont_know_protection",
        "nextStep" : "InterstitialStep",
        "route":"https://asurion.com/claims"
      }
    ]
    },
    {
    "title" : "Smart home",
    "planType" : "Asurion protection plan",
    "id" : 7,
    "img" : Thermostat,
    "value" : "thermostat",
    "nextStep" : "CoverageStep",
    "route":"",
    "peril" : [],
    "coverage" : [
      {
        "title" : "Yes",
        "img" : YesProtection,
        "value" : "yes_protection",
        "nextStep" : "InterstitialStep",
        "route":"https://asurion.com/claims"
      },
      {
        "title" : "No",
        "img" : NoProtection,
        "value" : "No_protection",
        "nextStep" : "InterstitialStep",
        "route":"https://new.ubreakifix.com/?device=1251"
      },
      {
        "title" : "I'm not sure.",
        "img" : DontKnowProtection,
        "value" : "Dont_know_protection",
        "nextStep" : "InterstitialStep",
        "route":"https://asurion.com/claims"
      }
    ]
    },
    {
    "title" : "Something else",
    "id" : 8,
    "img" : Instrument,
    "planType" : "Asurion protection plan",
    "value" : "something_else",
    "nextStep" : "",
    "route":"https://new.ubreakifix.com/?device=1251",
    "peril" : [],
    "coverage" : []
    }
]
