import React from "react";
import Popup from "../Popup";
import Matrix from "../Matrix";
import styled from "styled-components/macro";

const BoldElement = styled.span`
  font-weight:700;
`;

const styledTitle = (productType) => {
  return(
    <span>
    What’s wrong with your <BoldElement>{productType}?</BoldElement>
    </span>
  )
};

const PerilStep = (props) => {
  return(
    <Popup
      data={props.data}
      title={styledTitle(props.data.title.toLowerCase())}
      handleBack={props.handleBack}
      handleClose={props.handleClose}
      shouldAnimate={props.shouldAnimate}
      handleFirstAnimation={props.handleFirstAnimation}
    >
      <Matrix
        data={props.data.peril}
        cols= "3"
        handleSelection={props.handleSelection}
        userInput={props.userInput}
      />
    </Popup>
  );
};

export default PerilStep;
